@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:700);

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}

.dual-listbox .dual-listbox__search{-webkit-appearance:none;appearance:none;background-clip:padding-box;background-color:#fff;border:1px solid #dbdfea;border-radius:4px;color:#3c4d62;display:block;font-size:.8125rem;font-weight:400;line-height:1.25rem;padding:.4375rem 1rem;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;width:100%;}
.dual-listbox .dual-listbox__search:focus{background-color:#fff;border-color:#6576ff;box-shadow:0 0 0 3px rgba(101,118,255,.1);color:#3c4d62;outline:0;}
.dual-listbox .dual-listbox__search::-webkit-input-placeholder{color:#b6c6e3;opacity:1;}
.dual-listbox .dual-listbox__search::placeholder{color:#b6c6e3;opacity:1;}
.dual-listbox .dual-listbox__search:disabled{background-color:#f5f6fa;opacity:1;}
@media (prefers-reduced-motion:reduce){
.dual-listbox .dual-listbox__button{transition:none;}
.dual-listbox .dual-listbox__search{transition:none;}
}
.rdl-controls{display: flex;}
.rdl-sr-only{@apply hidden}
.rdl-control option:hover{@apply bg-orange-500}
.rdl-control option:active,.rdl-control>option:focus{background-color:#ebedff!important;}
label{cursor:pointer;}
.react-dual-listbox *{box-sizing:border-box;}
.react-dual-listbox select:disabled{background:#eee;cursor:not-allowed;}
.react-dual-listbox select{font-family:inherit;line-height:1.42857; @apply w-full}
.rdl-control{border:1px solid #ccc;border-radius:2px;color:#333;display:block;font-size:14px;padding:8px 12px;width:100%;}
.rdl-control-container{display:flex;flex:1 0 auto;flex-direction:column;}
.rdl-control-label{-webkit-margin-before:1rem;border-left:1px solid #e5e9f2;border-radius:4px 4px 0 0;border-right:1px solid #e5e9f2;border-top:1px solid #e5e9f2;font-size:1rem;font-weight:500;margin-bottom:0!important;margin-top:1rem;padding:.5rem 1rem;width:100%;}
.rdl-control{@apply h-full rounded-lg; border:1px solid #e5e9f2;flex:1 0 auto;margin-top:0;overflow-y:auto;padding:0;width:300px;}
.rdl-control:focus{outline:none;}
.rdl-control option{@apply m-2 cursor-pointer block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded}
.rdl-control option:active,.rdl-control option:focus{@apply bg-red-600}
.dual-listbox{height: 100%;width: 100%;}
.dual-listbox .rdl-actions-left,.dual-listbox .rdl-actions-right{display:flex;margin:3px 10px; @apply flex-row lg:flex-col}
.rdl-no-options{border: 1px solid #e5e9f2; width:100%; @apply flex items-center justify-center text-center mt-10 lg:mt-0 font-medium px-4 min-h-[300px] rounded-lg h-[96%]; }
.react-dual-listbox button{font-family:inherit;line-height:1.42857;}
.rdl-actions{display:inline-flex; @apply flex-row lg:flex-col}
.rdl-move:focus{border:none;outline:none!important;}
.rdl-move:disabled{cursor:not-allowed;opacity:.5;}
.rdl-move:last-child{margin-bottom:0;}
.rdl-list-box.rdl-selected .rdl-filter-container { display: none; }
.rdl-list-box.rdl-available .rdl-filter-container label { display: none;}
.rdl-list-box.rdl-available .rdl-filter-container input { @apply max-w-[300px] w-full text-sm mb-2 rounded-lg}
.rdl-list-box.rdl-available{ flex: 1; @apply order-last}
.rdl-list-box.rdl-selected{ flex: 1; @apply order-first}
.rdl-controls .rdl-actions{flex: 0; @apply justify-center m-6 lg:m-0}
.rdl-control-container{height: 96%; @apply bg-green-500 text-8xl min-h-[300px] rounded-lg;}
.rdl-list-box.rdl-available .rdl-control-container{height: 86%;}
.rdl-list-box.rdl-available .rdl-control option {@apply bg-gray-500 hover:bg-blue-600}
.rdl-controls{@apply h-full flex-col lg:flex-row}

.selectAddDisciplinaOrder__control{
    min-height: 40px !important;
    border-radius: 4px 0px 0px 4px !important;
    border-color: hsl(217deg 91% 60%) !important;
    min-width: 180px;
}

*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  *::-webkit-scrollbar-track {
    background-color: #e2e2f8;
    border-radius: 6px;
    background-clip: content-box;
  }
  
*::-webkit-scrollbar-track:horizontal {
    margin: 0 2rem;
  }

 *:-webkit-scrollbar-track:vertical {
    margin: 0 2rem;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #6e6edb;
    border-radius: 6px;
  }

  .diario .rdt_TableCell > div:first-child{
    width: 100%;
    height: 100%;
    display: flex;
    @apply p-1 truncate justify-center;
  }

  /* Loader */
  .loader {
    @apply bg-black h-16 relative w-16;
    border-radius: 50%;
    transform: rotate(45deg);
    animation: rotate 2s linear infinite;
  }
  .loader:before {
    @apply h-8 absolute w-4;
    content: "";
    left: 50%;
    top: 50%;
    background-color: rgba(255, 61, 0, 1);
    transform: skew(5deg, 60deg) translate(-50%, -5%);
  }
  .loader:after {
    @apply bg-white h-1 absolute w-1;
    content: "";
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes rotate {
    0% { transform: rotate(45deg)}
    30% , 50% , 70%  { transform: rotate(230deg)}
    40% , 60% , 80% { transform: rotate(240deg)}
    100% {  transform: rotate(245deg)}
 }
 .circulo64 {
  height: 64px;
    width: 64px;
    border-radius: 50%;
 }
 /* Loader */

 /* Loader 2 */
 .loader2 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: relative;
  background: #FFF;
  box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #FFF;
    box-shadow: -24px 0 rgb(251 146 60), 24px 0 #FFF;
  }
  66% {
    background: rgb(251 146 60);
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  }
  100% {
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 rgb(251 146 60);
  }
}
 /* Loader 2 */

 /* POMODORO */
#tomate .body{text-align:center;}
#clock{font-size:4em;}
.svg.radial-progress{position:relative;display:inline-block;}


/* POMODORO */

/* checkbox flip */
/*! CSS Used from: Embedded */
.tgl + .tgl-btn{box-sizing:border-box;}
.tgl + .tgl-btn::selection{background:none;}
.tgl + .tgl-btn{outline:0;display:block;width:4em;height:2em;position:relative;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
.tgl + .tgl-btn:after,.tgl + .tgl-btn:before{position:relative;display:block;content:"";width:50%;height:100%;}
.tgl + .tgl-btn:after{left:0;}
.tgl + .tgl-btn:before{display:none;}
.tgl:checked + .tgl-btn:after{left:50%;}
.tgl-flip + .tgl-btn{padding:2px;transition:all 0.2s ease;font-family:sans-serif;perspective:100px;}
.tgl-flip + .tgl-btn:after,.tgl-flip + .tgl-btn:before{display:inline-block;transition:all 0.4s ease;width:100%;text-align:center;position:absolute;line-height:2em;font-weight:bold;color:#fff;position:absolute;top:0;left:0;-webkit-backface-visibility:hidden;backface-visibility:hidden;border-radius:4px;}
.tgl-flip + .tgl-btn:after{content:attr(data-tg-on);background:#02C66F;transform:rotateY(-180deg);}
.tgl-flip + .tgl-btn:before{background:#FF3A19;content:attr(data-tg-off);}
.tgl-flip + .tgl-btn:active:before{transform:rotateY(-20deg);}
.tgl-flip:checked + .tgl-btn:before{transform:rotateY(180deg);}
.tgl-flip:checked + .tgl-btn:after{transform:rotateY(0);left:0;background:#7FC6A6;}
.tgl-flip:checked + .tgl-btn:active:after{transform:rotateY(20deg);}

/*! CSS Used from: https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css */
.tg-list-item input{overflow:visible;}
.tg-list-item input{font-family:sans-serif;font-size:100%;line-height:1.15;margin:0;}
[type=checkbox]{box-sizing:border-box;padding:0;}
/*! CSS Used from: Embedded */
.tgl{display:none;}
.tgl,.tgl:after,.tgl:before{box-sizing:border-box;}
.tgl::selection,.tgl:after::selection,.tgl:before::selection{background:none;}
/* checkbox flip */
.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
/* botao de acao no painel */
.acaobotao .container {
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.acaobotao #toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.acaobotao .button {
  position: absolute;
  z-index: 999;
  background: #8974fb;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -12px;
}
.acaobotao .button:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  background: #fff;
  transform: rotate(90deg);
  transition: all 0.4s ease;
}
.acaobotao .button:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  background: #fff;
  transition: all 0.4s ease;
}
.acaobotao .nav {
  opacity: 0;
  transition: all 0.4s ease-in-out;
  background: #fff;
  width: 100%;
  position: absolute;
  bottom:22px;
  left: -100px;
  border-radius: 5px;
  transform: translateY(0%) translateX(120%);
  box-shadow: 2px 3px 10px 0 rgba(81, 81, 81, 0.1);
  border: 1px solid #e4e4e4;
  padding: 10px;
}
.acaobotao .nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.acaobotao #toggle:checked ~ .nav {
  opacity: 1;
  transform: translateY(10%);
}
.acaobotao #toggle:checked ~ .button:before {
  transform: rotate(225deg);
}
.acaobotao #toggle:checked ~ .button:after {
  transform: rotate(135deg);
}

/* fim - botao de acao no painel */
/* inicio - tudo Certo */
.confetti {
  position: absolute;
  animation: grow 1.4s cubic-bezier(0.42, 0, 0.275, 1.155) infinite;
}
.confetti:nth-child(1) {
  width: 12px;
  height: 12px;
  left: 12px;
  top: 16px;
}
.confetti:nth-child(2) {
  width: 18px;
  height: 18px;
  left: 168px;
  top: 84px;
}
.confetti:nth-child(3) {
  width: 10px;
  height: 10px;
  left: 32px;
  top: 142px;
}
.confetti:nth-child(4) {
  width: 20px;
  height: 20px;
  left: 82px;
  top: 2px;
}
.confetti:nth-child(5) {
  width: 14px;
  height: 14px;
  left: 125px;
  top: 132px;
}
.confetti:nth-child(6) {
  width: 10px;
  height: 10px;
  left: 16px;
  top: 16px;
}
.confetti:nth-child(1) {
  animation-delay: 0.7s;
}
.confetti:nth-child(2) {
  animation-delay: 1.4s;
}
.confetti:nth-child(3) {
  animation-delay: 2.1s;
}
.confetti:nth-child(4) {
  animation-delay: 2.8s;
}
.confetti:nth-child(5) {
  animation-delay: 3.5s;
}
.confetti:nth-child(6) {
  animation-delay: 4.2s;
}

.checkmark {
  position: relative;
  padding: 30px;
  animation: checkmark 5.6s cubic-bezier(0.42, 0, 0.275, 1.155) infinite;
}

.checkmark__check {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate3d(-50%, -50%, 0);
  fill: #fff;
}

.checkmark__back {
  width: 100px;
  animation: rotate 35s linear both infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes grow {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/* inicio - tudo Certo */
/* background assine */
.shine-button::before {
  content: '';
  width: 80px;
  height: 300%;
  border: none ;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%) rotate(35deg);
background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.35) 50%, rgba(255,255,255,0) 100%);
 animation: shine 3s infinite;
}

@keyframes shine {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
  /* background assine */